


























































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { GetForbidList, BanApplyAccount } from "@/request/manage";
@Component({
  components: {
    MainHeader,
    NavLeft,
  },
})
export default class Name extends mixins(listMixin) {
  private data: any = [];
  private ifShowForbid: any = false;
  private curUser: any = {};
  /**
   * @description 禁用或启用账号
   */
  private banApplyAccount(user: any) {
    this.curUser = JSON.parse(JSON.stringify(user));
    this.ifShowForbid = true;
  }
  private cancelForbid() {
    this.ifShowForbid = false;
    this.getList();
  }
  private confirmForbid() {
    let arr: any = [];
    this.curUser["角色"].forEach((ele: any) => {
      arr.push({
        role_id: ele.role.role_id,
        status: ele.role.status,
      });
    });
    const params: any = {
      user_id: this.curUser.user_id,
      角色: arr,
    };
    BanApplyAccount(this, params).then((data: any) => {
      this.ifShowForbid = false;
      this.$message.success("修改成功");
      this.getList();
    });
  }
  /**
   * @description 自动计算角色
   */
  private calJuese(juese: any) {
    let jigouText = "";
    juese["组织机构"].forEach((jigou: any) => {
      jigouText += jigou["名称"] + "-";
    });
    jigouText = jigouText.substring(0, jigouText.length - 1);
    return juese["role"]["名称"] + "(" + jigouText + ")";
  }
  getList() {
    const params: any = {
      params: {
        order_field: this.filters.sort_field,
        order_value: this.filters.sort_value,
        current_page: this.filters.current_page,
        page_num: this.filters.page_count,
      },
    };
    localStorage.setItem("forbidFilter", JSON.stringify(this.filters));
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetForbidList(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.data = res.data;
        this.filters.total_count = res.total_count;
        this.filters.total_page = res.total_page;
        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  mounted() {
    this.getList();
  }
}
